import { collection, deleteDoc, doc, onSnapshot, setDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { Link,  useParams } from 'react-router-dom'
import { auth, fireStore } from '../firebase'
import { v4 } from 'uuid'
import { onAuthStateChanged,signInWithEmailAndPassword,sendPasswordResetEmail } from 'firebase/auth'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { Helmet } from 'react-helmet'
import Navigation from './Navigation'
export default function Cloth() {
    const {clothId} = useParams()
    const [cloth,setcloth] = useState([])
    const [cart,setcart] = useState([])
    const [cartError,setcartError] = useState('')
    const [selectedColorError,setselectedColorError] = useState('')
    const [loading,setloading] = useState(false)
    const[forgotPassword,setforgotPassword] = useState(false)
    const[sent,setsent] = useState(false)

    const[forgotPasswordError,setforgotPasswordError] = useState('')
    const[forgotPasswordEmail,setforgotPasswordEmail] = useState('')
    const[resetEmailError,setresetEmailError] = useState('')
    const [uid,setuid] = useState('')
    const[fullName,setfullName] = useState('')
    const[fullNameError,setfullNameError] = useState('')
    const[email,setemail] = useState('')
    const[emailError,setemailError] = useState('')
    const[password,setpassword] = useState('')
    const[passwordError,setpasswordError] = useState('')
    const[signInError,setsignInError] = useState('')
    const[isLoading,setIsLoading] = useState(true)
    const[uploadMessage,setuploadMessage] = useState('')
    const[design,setdesign] = useState([])
    const[designError,setdesignError] = useState('')
    const history = useHistory()

    const [signinpop,setsigninpop] = useState(false)


  const [currentIndexes, setCurrentIndexes] = useState({});
  const [selectedColor, setSelectedColor] = useState('');

  const [navPop,setNavPop] = useState(false)

  function navPopHandler(){
    if(navPop === true){
      setNavPop(false)
    }
    else{
      setNavPop(true)
    }
  } 

  const handleColorSelect = (color) => {
    setSelectedColor(color);
    setselectedColorError('')
  };
useEffect(() => {

  const unsubscribe = onSnapshot(doc(fireStore, 'clothes', clothId), (snapshot) => {
   if(snapshot.exists()){
    setcloth((prevcloth) => [{ ...snapshot.data() }]);
   }
   setIsLoading(false)
  });

  return unsubscribe;
}, [clothId, fireStore]);
    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
        
            const uid = user.uid;
            setuid(uid)

      
      
      
                
            const userDocRef = doc(fireStore, 'TotalUsers', uid);
            const cartCollectionRef = collection(userDocRef, 'Cart');
      
            const unsubscribeCartCollection = onSnapshot(cartCollectionRef, (cartSnapshot) => {
              const cartItems = cartSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
              setcart(cartItems);
            });
                  
              
            return () => {
              unsubscribeCartCollection();
            };
              
          
      
                 
      
                 
                 
      
                    
          
      
            
      
           
            
            // ...
          } else {
            console.log("logged out")
            
          }
        });
      
        
          
      
        return () => {
          unsubscribe();
          
        };
      }, []);


      function handleImgSelect(event) {
        const selectedFiles = (event.target.files[0]);
      
          // Check file size
          
         const maxSize = 5 * 1024 * 1024; // 5MB
        // Check file type
          if (selectedFiles.size > maxSize) {
            setuploadMessage(`File ${selectedFiles.name} exceeds the size limit of 5MB.`);
      
            return false;
      
          }
          else{
            setuploadMessage("")
            setdesign([])
          }
        
        
          setdesign(selectedFiles);
      
      
      
        // Clear the file input value to allow selecting the same file again
        event.target.value = '';
      }

    function addToCart(clothColor){
      window.scrollTo(0, 0);

      if(cart.length <= 4){
        if(clothColor.length > 1){
          if(selectedColor.length ===0){
            setselectedColorError('error')

          }
          else{
            setDoc(doc(fireStore,'TotalUsers',uid,'Cart',clothId),{
              clothId:clothId,
              chosenColor:selectedColor
          })
          }
        }
        else{
          setDoc(doc(fireStore,'TotalUsers',uid,'Cart',clothId),{
            clothId:clothId,
            chosenColor:selectedColor
        })
        }
       
      }
      else{
        setcartError('Your cart is full. You are only allowed to shop for five Clothes')

      }
    }

    function shopItem(clothColor){
      window.scrollTo(0, 0);

      if(cart.length <= 4){
        if(clothColor.length > 1){
          if(selectedColor.length ===0){
            setselectedColorError('error')

          }
          else{
            setDoc(doc(fireStore,'TotalUsers',uid,'Cart',clothId),{
              clothId:clothId,
              chosenColor:selectedColor
          }).then(
            history.push(`/collection/${uid + v4().slice(0,30)}/checkout`)
  
          )
          }
        }
        else{
          setDoc(doc(fireStore,'TotalUsers',uid,'Cart',clothId),{
            clothId:clothId,
            chosenColor:selectedColor
        }).then(
          history.push(`/collection/${uid + v4().slice(0,30)}/checkout`)

        )
        }
       
      }
      else{
        setcartError('Your cart is full. You are only allowed to shop for five Clothes')

      }

    }

   


    function ClothGallery({ cloth, currentIndex, handlePrevious, handleNext }) {
        return (
          <div className=''>
             <div className='w-full'>
             <div className="relative w-full">
             {cloth.clothPhotos.length >= 2 && (
                     <button
                     type="button"
                     className="z-100 absolute top-0 start-0 z-30 flex items-center justify-center h-full px-2 cursor-pointer group focus:outline-none"
                     data-carousel-prev
                   >
                   <span onClick={() => handlePrevious(cloth.clothPhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
      
                       <svg
                         
                         className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                         aria-hidden="true"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 6 10"
                       >
                         <path
                           stroke="currentColor"
                           stroke-linecap="round"
                           stroke-linejoin="round"
                           stroke-width="2"
                           d="M5 1 1 5l4 4"
                         />
                       </svg>
                       <span className="sr-only">Previous</span>
                     </span>
                   </button>
               
                )}
      
                {cloth.clothPhotos.length >= 2 && (
                   <div
                   type="div"
                   className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-2  group focus:outline-none"
                   data-carousel-next
                 >
                   <span onClick={() => handleNext(cloth.clothPhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
                     <svg
                       
                       className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                       aria-hidden="true"
                       xmlns="http://www.w3.org/2000/svg"
                       fill="none"
                       viewBox="0 0 6 10"
                     >
                       <path
                         stroke="currentColor"
                         stroke-linecap="round"
                         stroke-linejoin="round"
                         stroke-width="2"
                         d="m1 9 4-4-4-4"
                       />
                     </svg>
                     <span className="sr-only">Next</span>
                   </span>
                 </div>
      
                )}
               {cloth && cloth.clothPhotos.map((photo, index) => (
              <div className={`productCard-card productCardroot-class-name1  ${index === currentIndex ? 'flex' : 'hidden'}`} style={{backgroundImage:`url(${photo})`,width:'100%'}}>
         
        </div>
                    ))}
              </div>
            
            </div>
           
          </div>
          
        );
      }
      useEffect(() => {
        const initialIndexes = cloth.reduce((acc, cloth) => {
          acc[cloth.id] = 0;
          return acc;
        }, {});
        setCurrentIndexes(initialIndexes);
      }, [cloth]);
      
      const handlePrevious = (clothPhotos, id) => {
        setCurrentIndexes((prevIndexes) => ({
          ...prevIndexes,
          [id]: (prevIndexes[id] === 0 ? clothPhotos.length - 1 : prevIndexes[id] - 1),
        }));
      };
      
      const handleNext = (clothPhotos, id) => {
        setCurrentIndexes((prevIndexes) => ({
          ...prevIndexes,
          [id]: (prevIndexes[id] === clothPhotos.length - 1 ? 0 : prevIndexes[id] + 1),
        }));
      };

      function signInPopSet(){
        setsigninpop(true)
      }

      function setsigninpopClose(){
        setsigninpop(false)
      }
      function emailSet(e){
        setemail(e.target.value)
        if(e.target.value.length === 0){
          setemailError('error')
  
        }
        else{
          setemailError('')
        }
      }
  
      function passwordSet(e){
        setpassword(e.target.value)
        if(e.target.value.length === 0){
          setpasswordError('error')
  
        }
        else{
          setpasswordError('')
        }
      }
  
      function signIn(e) {
        e.preventDefault();
        
       if(email.length !=0 && password.length !=0){
        signInWithEmailAndPassword(auth, email, password).then(setIsLoading(true))
        .then(() => {
          if (email.trim() === 'admin@admin.com' && password.trim() === 'admin@admin.com') {
            history.push("/admin");
            setIsLoading(false)
  
          }
          
            else{
          history.push(`/cloth/${clothId}`);
          setIsLoading(false)
          setsigninpop(false)
  
          }
      })
      .catch((error) => {
        setIsLoading(false)
          // Handle specific error cases
          if (error.code === "auth/user-not-found") {
              setsignInError('User not found.');
          } else if (error.code === "auth/invalid-credential") {
              setsignInError('Invalid login credentials');
          } else if (error.code === "auth/network-request-failed") {
            setsignInError('Check your Internet Connection');
  
          }
          else if (error.code === "auth/invalid-email") {
            setsignInError('Invalid login credentials');
  
          }
           else {
              setsignInError(error.message);
          }
      });
       }
       
    }
  
    function resetEmail(){
        if (forgotPasswordEmail.length === 0) {
          setforgotPasswordEmail('error');
        } else {
          setIsLoading(true);
      
          sendPasswordResetEmail(auth, forgotPasswordEmail)
            .then(() => {
              setIsLoading(false);
              setsent(true);
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              setresetEmailError(errorMessage);
              setIsLoading(false);
            });
        }   if (forgotPasswordEmail.length === 0) {
          setforgotPasswordError('error');
        } else {
          setIsLoading(true);
      
          sendPasswordResetEmail(auth, forgotPasswordEmail)
            .then(() => {
              setIsLoading(false);
              setsent(true);
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              setresetEmailError(errorMessage);
              setIsLoading(false);
            });
        }
    }
  
    function successRemove(){
      setsent(false)
      setforgotPassword(false)
      setsigninpop(false)
    }
    function resetEmailSet(e){
      setforgotPasswordEmail(e.target.value)
      if(e.target.value.length === 0){
       setforgotPasswordError('error')

      }
      else{
       setforgotPasswordError('')
      }
    }

    function openForgotPassword(){
      setTimeout(() => {
        setIsLoading(true)
        setTimeout(() => {
          setIsLoading(false)
          setforgotPassword(true)
        }, 1000);
        
      }, 1000);
    }
    function formatNumber(num) {
      if(num){
          return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      };
    }

  return (
    <div >

         <Helmet>
        <title>Ephratawi Design: Cloth</title>
        <meta property="og:title" content="Ephratawi Design" />
      </Helmet>
<Navigation navigationPop={navPop} component='Cloth'/>
      <div id='desktop' className='mb-10'>
    
        {
          isLoading === true ?
          <div className='signInpop ' style={{zIndex:1001}}>
        <div style={{marginTop:'20px'}} class="bg-white max-w-md mx-auto flex items-center justify-center shadow border rounded  w-full p-10 ">
   
   <div
   className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
   role="status"></div></div></div>
          :
          cloth.length != 0?
          <div className='' style={{marginTop:'60px'}}>
      <div className={`${selectedColorError.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
You must choose a color

</div>
</div>
</div>

          <div className={`${cartError.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{cartError}
</div>
</div>
</div>

{
signinpop === true &&
<div className='signInpop ' style={{zIndex:1001}}>

{
   isLoading === true ?
   <div style={{marginTop:'20px'}} class="bg-white max-w-md mx-auto flex items-center justify-center shadow border rounded  w-full p-10 ">
   
   <div
   className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
   role="status"></div></div>
   :
   sent ===  true?
   <div style={{width:'40%'}}class="bg-white shadow border rounded  w-full p-10 ">
                <div  id='successCourseAddDesktop' style={{display:'flex',justifyContent:'center',alignItems:'center'}} className='flex items-center justify-center'>


<div className="relative p-4 w-full  "style={{width:"100%"}}>
<div className="relative p-4 text-center bg-white rounded-lg   sm:p-5" >
 
   <button  onClick={successRemove} type="button" className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
       <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
       <span className="sr-only">Close modal</span>
   </button>


   <div className="w-12 h-12 rounded-full bg-green-400 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
       <svg aria-hidden="true" className="w-8 h-8 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
       <span className="sr-only">Success</span>
   </div>
   <p className="mb-4 text-lg font-semibold text-gray-900  capitalize">Email has been sent</p>
                <p className='borel'>Please check your email inbox for a password recovery link</p>
</div>
</div>



</div> 
</div>
:
   forgotPassword === true ?
   <div style={{width:'40%'}}class="bg-white shadow border rounded  w-full p-10 relative">
       <button  onClick={successRemove} type="button" className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
       <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
       <span className="sr-only">Close modal</span>
   </button>
<div className={`${resetEmailError.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{resetEmailError}
</div>
</div>
</div>
<p style={{fontSize:'20px'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800">Reset Password</p>



<div className="relative mx-auto mt-12" style={{width:'80%'}}>
<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Email</p>
<input onChange={(e)=>resetEmailSet(e)}style={{fontSize:'12px',height:'60px'}}   required  autoComplete="off" placeholder="kebede@gmail.com" type="email" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${forgotPasswordError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Email Can't be empty
</div>
</div>
</div>
</div>

<div className="flex justify-center mt-12 mx-auto" style={{width:'80%'}}>
<button
type='button'
onClick={resetEmail}
style={{fontSize:'12px',margin:'auto',background:'#31ffaa',height:'55px'}}
className='flex justify-center items-center par border w-full text-gray-800  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
>
Reset Password
</button>
</div>
<div class="w-full flex items-center justify-between py-5 mt-4">
</div>



</div>
:
<div style={{width:'40%'}}class="bg-white shadow border rounded  w-full p-10 ">
     <div className={`${signInError.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{signInError}
</div>
</div>
</div>
   <p style={{fontSize:'20px'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800">Log In</p>
  
   
<svg onClick={setsigninpopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>

   <form class="max-w-md mx-auto" style={{marginTop:'20px'}}>
 <div className={`${emailError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'12px'}}>
Email Can't be empty
</div>
</div>
</div>
<div className={`${passwordError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'12px'}}>
Password Can't be empty
</div>
</div>
</div>
<div className={`${signInError ? "block":"hidden"}`}>
<div className="flex items-center p-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'12px'}}>
{signInError}
</div>
</div>
</div>
  <div class="relative z-0 w-full my-5 group">
      <input onChange={e=>emailSet(e)} type="email" name="floating_email" id="floating_email" style={{borderBottom:'1px solid black',borderRadius:'0',marginBottom:'20px'}}class="input-signUp block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
      <label for="floating_email" class=" peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-green-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address</label>
      <div className={`${emailError === "error" ? "block":"hidden"}`}>

</div>
  </div>
  


    <div class="relative z-0 w-full mb-5 group">
        <input type="password" onChange={e=>passwordSet(e)} name="floating_phone" id="floating_phone" style={{borderBottom:'1px solid black',borderRadius:'0',marginBottom:'20px'}}class="input-signUp block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
        <label for="floating_phone" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7  scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-green-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Password </label>
    </div>
  
   
              <button onClick={signIn} type="submit" className="button-secondary button bg-transparent">Sign In</button>

              <p onClick={openForgotPassword} style={{fontSize:'12px'}} className='cursor-pointer mt-5 flex justify-center '>
                    Forgot Password?
                     
                      
                    </p>
</form>
    <div class="w-full flex items-center justify-between py-5 mt-4">
            <hr class="w-full bg-gray-400"/>
            <p class="text-base font-medium leading-4 px-2.5 text-gray-400"></p>
            <hr class="w-full bg-gray-400  "/>
           </div>
 
       <p class="text-center par focus:outline-none text-sm mt-8 font-medium leading-none text-gray-500">Don't have an account? <Link to='/register'><a   class="hover:text-gray-500 focus:text-gray-500 focus:outline-none focus:underline hover:underline text-sm font-medium leading-none  text-gray-800 cursor-pointer"> Sign up here</a></Link></p>
     
      
   </div>
}
</div>

}
<div className="flex justify-between px-5 items-center">



</div>

<div >

<div  >
{
cloth && cloth.map(i=>{
const{clothSize,clothTitle,clothColor,clothDescription,clothIngredients,clothPrice,clothFabric,clothDelivery} = i
return(
    <div class=" ">
    <div class=" mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center">
            <div class="" style={{width:'40%'}}>
            <h1 className='barlow pl-10 pt-10 mb-5 flex items-center capitalize' style={{fontSize:"12px"}}> 
    <Link className='underline' to={`/collection`}>Clothes</Link>
<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg> 


 {clothTitle}</h1>
            {cloth && cloth.map((cloth) => (
<div key={cloth.id}>
<ClothGallery
cloth={cloth}
currentIndex={currentIndexes[cloth.id] || 0}
handlePrevious={(clothPhotos) => handlePrevious(clothPhotos, cloth.id)}
handleNext={(clothPhotos) => handleNext(clothPhotos, cloth.id)}
/>
</div>
))}
           
            </div>
            <div class="ml-8" style={{width:'60%'}}>
              <div className="flex w-full justify-between items-center mb-2">
              <h2 class="nds-text _1MF91zHG css-7vq5hu e1yhcai00 appearance-display2 color-var(--podium-cds-color-text-primary) weight-medium uppercase" style={{fontSize:'25px'}}>{clothTitle}</h2>
              <span class="text-gray-900 header"style={{fontSize:'15px'}}> {clothPrice} ETB</span>

              </div>
                <p class="text-gray-600  text-sm mt-5 par break-all mb-6">
                {clothDescription} </p>
                {/* <div class="flex my-4">
                   
                    <div >
                        <span class="font-bold text-gray-700 ">Cloth Fabric:</span>
                        <span class="text-gray-600 par"> {clothFabric.map}</span>
                    </div>
                </div> */}
                {/* <div class="mb-4">
                   {
                    clothColor && clothColor.length > 1 ?
                    <span class="font-bold text-gray-700 ">Choose cloth Color:</span>
                    :
                    <span class="font-bold text-gray-700 "> Color:</span>
                   }
                    <div>
<div className="flex mt-4">
 
{clothColor && clothColor.length > 1 ?
clothColor && clothColor.map((color, index) => (
<div key={index} className="flex items-center mx-2">
<div class="flex items-center mb-4 ">
<input type="checkbox" id={`color-${index}`}
checked={selectedColor === color}
onChange={() => handleColorSelect(color)} class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"/>

<label
htmlFor={`color-${index}`}
className="ml-1 w-7 h-7 rounded-full shadow border cursor-pointer"
style={{ backgroundColor: color }}
></label>
</div>

</div>
))
:
clothColor && clothColor.map((color, index) => (
  <div key={index} className="flex items-center mx-2">
  <div class="flex items-center mb-4 ">

  <label
  htmlFor={`color-${index}`}
  className="ml-1 w-7 h-7 rounded-full shadow border "
  style={{ backgroundColor: color }}
  ></label>
  </div>
  
  </div>
  ))}
</div>
</div>
                </div> */}
                <div class="mb-4">
                    <span class="font-bold text-gray-700">Cloth Size:</span>
                   <p className='text-gray-600 pa mt-2r' style={{fontSize:'15px'}}>

                  {clothSize}
                   </p>

                </div>
                <div class="mb-4">
                    <span class="font-bold text-gray-700">Cloth Fabric:</span>
                   <p className='text-gray-600 par' style={{fontSize:'15px'}}>

                 {clothFabric.map(i=>{
                  return(i)
                 })}
                   </p>

                </div>
                <div class="mb-4">
                    <span class="font-bold text-gray-700">Delivery Status:</span>
                   <p className='text-gray-600 par'>

                  {clothDelivery === 'Shop Pickup' ? `We do not offer delivery services, so you must pick up the cloth from our shop`:'Delivery Available'}
                   </p>

                </div>
                <div className='mb-8'>
                   
                </div>


                {
                  uid.length === 0 ?
                  <button onClick={signInPopSet} className="mt-5  par w-full flex items-center justify-center rounded-md bg-slate-900 px-5 py-4 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                  Add to cart
                </button>
                : <button onClick={()=>addToCart(clothColor)} className="mt-5  par w-full flex items-center justify-center rounded-md bg-slate-900 px-5 py-4 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-2 h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
                Add to cart
              </button>

                }

{
uid.length === 0?
<button onClick={signInPopSet} class="flex w-full items-center justify-center rounded-lg bg-[#31ffaa] hover:bg-[#41f1a8] px-5 py-4 text-sm font-medium text-black border  focus:outline-none focus:ring-4 focus:ring-blue-300  mt-4">Proceed to Checkout</button>
:
<button onClick={()=>shopItem(clothColor)} class="flex w-full items-center justify-center rounded-lg bg-[#41f1a8] hover:bg-[#31ffaa] px-5 py-4 text-sm font-medium text-black border  focus:outline-none focus:ring-4 focus:ring-blue-300  mt-4">Proceed to Checkout</button>

}

            </div>
        </div>
    </div>
</div>

)
})

}
</div>

</div>

</div>
:
<div>
<div className='flex items-center justify-center flex-col  '>
<img src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/red%20velvet%20cloth-pana.svg?alt=media&token=2749019d-15fe-499d-af3e-8342c9ef434a" alt="No cloth" style={{width:"42%"}}/>
<h1 className='fjalla text-center text-gray-900 mt-8 barlow' style={{fontSize:"30px"}}>  We couldn't find any Clothes for you 




</h1>
<Link to='/collection'>
<button type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-7 py-4 me-2 mb-2">Shop Now</button> 

</Link>
</div>
</div>
        }
           

        </div>
        <div id='tablet' className='mb-10'>
    
        {
          isLoading === true ?
          <div className='signInpop ' style={{zIndex:1001}}>
        <div style={{marginTop:'20px'}} class="bg-white max-w-md mx-auto flex items-center justify-center shadow border rounded  w-full p-10 ">
   
   <div
   className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
   role="status"></div></div></div>
          :
          cloth.length != 0?
          <div className='' style={{marginTop:'60px'}}>
      <div className={`${selectedColorError.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
You must choose a color

</div>
</div>
</div>

          <div className={`${cartError.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{cartError}
</div>
</div>
</div>

{
signinpop === true &&
<div className='signInpop ' style={{zIndex:1001}}>

{
   isLoading === true ?
   <div style={{marginTop:'20px'}} class="bg-white max-w-md mx-auto flex items-center justify-center shadow border rounded  w-full p-10 ">
   
   <div
   className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
   role="status"></div></div>
   :
   sent ===  true?
   <div style={{width:'40%'}}class="bg-white shadow border rounded  w-full p-10 ">
                <div  id='successCourseAddDesktop' style={{display:'flex',justifyContent:'center',alignItems:'center'}} className='flex items-center justify-center'>


<div className="relative p-4 w-full  "style={{width:"100%"}}>
<div className="relative p-4 text-center bg-white rounded-lg   sm:p-5" >
 
   <button  onClick={successRemove} type="button" className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
       <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
       <span className="sr-only">Close modal</span>
   </button>


   <div className="w-12 h-12 rounded-full bg-green-400 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
       <svg aria-hidden="true" className="w-8 h-8 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
       <span className="sr-only">Success</span>
   </div>
   <p className="mb-4 text-lg font-semibold text-gray-900  capitalize">Email has been sent</p>
                <p className='borel'>Please check your email inbox for a password recovery link</p>
</div>
</div>



</div> 
</div>
:
   forgotPassword === true ?
   <div style={{width:'40%'}}class="bg-white shadow border rounded  w-full p-10 relative">
       <button  onClick={successRemove} type="button" className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
       <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
       <span className="sr-only">Close modal</span>
   </button>
<div className={`${resetEmailError.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{resetEmailError}
</div>
</div>
</div>
<p style={{fontSize:'20px'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800">Reset Password</p>



<div className="relative mx-auto mt-12" style={{width:'80%'}}>
<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Email</p>
<input onChange={(e)=>resetEmailSet(e)}style={{fontSize:'12px',height:'60px'}}   required  autoComplete="off" placeholder="kebede@gmail.com" type="email" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${forgotPasswordError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Email Can't be empty
</div>
</div>
</div>
</div>

<div className="flex justify-center mt-12 mx-auto" style={{width:'80%'}}>
<button
type='button'
onClick={resetEmail}
style={{fontSize:'12px',margin:'auto',background:'#31ffaa',height:'55px'}}
className='flex justify-center items-center par border w-full text-gray-800  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
>
Reset Password
</button>
</div>
<div class="w-full flex items-center justify-between py-5 mt-4">
</div>



</div>
:
<div style={{width:'40%'}}class="bg-white shadow border rounded  w-full p-10 ">
     <div className={`${signInError.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{signInError}
</div>
</div>
</div>
   <p style={{fontSize:'20px'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800">Log In</p>
  
   
<svg onClick={setsigninpopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
<path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
</svg>

   <form class="max-w-md mx-auto" style={{marginTop:'20px'}}>
 <div className={`${emailError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'12px'}}>
Email Can't be empty
</div>
</div>
</div>
<div className={`${passwordError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'12px'}}>
Password Can't be empty
</div>
</div>
</div>
<div className={`${signInError ? "block":"hidden"}`}>
<div className="flex items-center p-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'12px'}}>
{signInError}
</div>
</div>
</div>
  <div class="relative z-0 w-full my-5 group">
      <input onChange={e=>emailSet(e)} type="email" name="floating_email" id="floating_email" style={{borderBottom:'1px solid black',borderRadius:'0',marginBottom:'20px'}}class="input-signUp block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
      <label for="floating_email" class=" peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-green-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address</label>
      <div className={`${emailError === "error" ? "block":"hidden"}`}>

</div>
  </div>
  


    <div class="relative z-0 w-full mb-5 group">
        <input type="password" onChange={e=>passwordSet(e)} name="floating_phone" id="floating_phone" style={{borderBottom:'1px solid black',borderRadius:'0',marginBottom:'20px'}}class="input-signUp block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
        <label for="floating_phone" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7  scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-green-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Password </label>
    </div>
  
   
              <button onClick={signIn} type="submit" className="button-secondary button bg-transparent">Sign In</button>

              <p onClick={openForgotPassword} style={{fontSize:'12px'}} className='cursor-pointer mt-5 flex justify-center '>
                    Forgot Password?
                     
                      
                    </p>
</form>
    <div class="w-full flex items-center justify-between py-5 mt-4">
            <hr class="w-full bg-gray-400"/>
            <p class="text-base font-medium leading-4 px-2.5 text-gray-400"></p>
            <hr class="w-full bg-gray-400  "/>
           </div>
 
       <p class="text-center par focus:outline-none text-sm mt-8 font-medium leading-none text-gray-500">Don't have an account? <Link to='/register'><a   class="hover:text-gray-500 focus:text-gray-500 focus:outline-none focus:underline hover:underline text-sm font-medium leading-none  text-gray-800 cursor-pointer"> Sign up here</a></Link></p>
     
      
   </div>
}
</div>

}
<div className="flex justify-between px-5 items-center">



</div>

<div >

<div  >
{
cloth && cloth.map(i=>{
const{clothSize,clothTitle,clothColor,clothDescription,clothIngredients,clothPrice,clothFabric,clothDelivery} = i
return(
    <div class=" ">
    <div class=" mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center">
            <div class="" style={{width:'40%'}}>
            <h1 className='barlow pl-10 pt-10 mb-5 flex items-center capitalize' style={{fontSize:"12px"}}> 
    <Link className='underline' to={`/collection`}>Clothes</Link>
<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
</svg> 


 {clothTitle}</h1>
            {cloth && cloth.map((cloth) => (
<div key={cloth.id}>
<ClothGallery
cloth={cloth}
currentIndex={currentIndexes[cloth.id] || 0}
handlePrevious={(clothPhotos) => handlePrevious(clothPhotos, cloth.id)}
handleNext={(clothPhotos) => handleNext(clothPhotos, cloth.id)}
/>
</div>
))}
           
            </div>
            <div class="ml-8" style={{width:'60%'}}>
              <div className="flex w-full justify-between items-center mb-2">
              <h2 class="nds-text _1MF91zHG css-7vq5hu e1yhcai00 appearance-display2 color-var(--podium-cds-color-text-primary) weight-medium uppercase" style={{fontSize:'25px'}}>{clothTitle}</h2>
              <span class="text-gray-900 header"style={{fontSize:'15px'}}> {clothPrice} ETB</span>

              </div>
                <p class="text-gray-600  text-sm mt-5 par break-all mb-6">
                {clothDescription} </p>
                {/* <div class="flex my-4">
                   
                    <div >
                        <span class="font-bold text-gray-700 ">Cloth Fabric:</span>
                        <span class="text-gray-600 par"> {clothFabric.map}</span>
                    </div>
                </div> */}
                {/* <div class="mb-4">
                   {
                    clothColor && clothColor.length > 1 ?
                    <span class="font-bold text-gray-700 ">Choose cloth Color:</span>
                    :
                    <span class="font-bold text-gray-700 "> Color:</span>
                   }
                    <div>
<div className="flex mt-4">
 
{clothColor && clothColor.length > 1 ?
clothColor && clothColor.map((color, index) => (
<div key={index} className="flex items-center mx-2">
<div class="flex items-center mb-4 ">
<input type="checkbox" id={`color-${index}`}
checked={selectedColor === color}
onChange={() => handleColorSelect(color)} class="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"/>

<label
htmlFor={`color-${index}`}
className="ml-1 w-7 h-7 rounded-full shadow border cursor-pointer"
style={{ backgroundColor: color }}
></label>
</div>

</div>
))
:
clothColor && clothColor.map((color, index) => (
  <div key={index} className="flex items-center mx-2">
  <div class="flex items-center mb-4 ">

  <label
  htmlFor={`color-${index}`}
  className="ml-1 w-7 h-7 rounded-full shadow border "
  style={{ backgroundColor: color }}
  ></label>
  </div>
  
  </div>
  ))}
</div>
</div>
                </div> */}
                <div class="mb-4">
                    <span class="font-bold text-gray-700">Cloth Size:</span>
                   <p className='text-gray-600 par mt-2r' style={{fontSize:'15px'}}>

                  {clothSize}
                   </p>

                </div>
                <div class="mb-4">
                    <span class="font-bold text-gray-700">Cloth Fabric:</span>
                   <p className='text-gray-600 par' style={{fontSize:'15px'}}>

                 {clothFabric.map(i=>{
                  return(i)
                 })}
                   </p>

                </div>
                <div class="mb-4">
                    <span class="font-bold text-gray-700">Delivery Status:</span>
                   <p className='text-gray-600 par'>

                  {clothDelivery === 'Shop Pickup' ? `We do not offer delivery services, so you must pick up the cloth from our shop`:'Delivery Available'}
                   </p>

                </div>
                <div className='mb-8'>
                   
                </div>


                {
                  uid.length === 0 ?
                  <button onClick={signInPopSet} className="mt-5  par w-full flex items-center justify-center rounded-md bg-slate-900 px-5 py-4 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="mr-2 h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                    />
                  </svg>
                  Add to cart
                </button>
                : <button onClick={()=>addToCart(clothColor)} className="mt-5  par w-full flex items-center justify-center rounded-md bg-slate-900 px-5 py-4 text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-2 h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
                Add to cart
              </button>

                }

{
uid.length === 0?
<button onClick={signInPopSet} class="flex w-full items-center justify-center rounded-lg bg-[#31ffaa] hover:bg-[#41f1a8] px-5 py-4 text-sm font-medium text-black border  focus:outline-none focus:ring-4 focus:ring-blue-300  mt-4">Proceed to Checkout</button>
:
<button onClick={()=>shopItem(clothColor)} class="flex w-full items-center justify-center rounded-lg bg-[#41f1a8] hover:bg-[#31ffaa] px-5 py-4 text-sm font-medium text-black border  focus:outline-none focus:ring-4 focus:ring-blue-300  mt-4">Proceed to Checkout</button>

}

            </div>
        </div>
    </div>
</div>

)
})

}
</div>

</div>

</div>
:
<div>
<div className='flex items-center justify-center flex-col  '>
<img src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/red%20velvet%20cloth-pana.svg?alt=media&token=2749019d-15fe-499d-af3e-8342c9ef434a" alt="No cloth" style={{width:"42%"}}/>
<h1 className='fjalla text-center text-gray-900 mt-8 barlow' style={{fontSize:"30px"}}>  We couldn't find any Clothes for you 




</h1>
<Link to='/collection'>
<button type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-7 py-4 me-2 mb-2">Shop Now</button> 

</Link>
</div>
</div>
        }
           

        </div>
        <div id='mobile' >
    
        {
          isLoading === true ?
          <div className='signInpop ' style={{zIndex:1001}}>
          <div style={{marginTop:'20px',width:'80%',height:'60vh'}} class="bg-white max-w-md mx-auto flex items-center justify-center shadow border rounded  w-full p-10 ">
     
     <div
     className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
     role="status"></div></div></div>
          :
          cloth != 0 ?
          <div>

          <div className={`${cartError.length !== 0 ? "block":"hidden"}`}>
 <div style={{paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
 <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
 </svg>
 <span className="sr-only">Info</span>
 <div className='Mooli' style={{fontSize:'3vw'}}>
  
 {cartError}
 
 </div>
 </div>
 </div> 
 <div className={`${selectedColorError.length !== 0 ? "block":"hidden"}`}>
 <div style={{paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',width:'100%'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
 <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
 </svg>
 <span className="sr-only">Info</span>
 <div className='Mooli' style={{fontSize:'3vw'}}>
  
 You must choose a color
 
 </div>
 </div>
 </div> 
          {
           signinpop === true &&
<div className='signInpop ' style={{zIndex:1001}}>
 
           {  isLoading === true ?
             <div className='signInpop ' style={{zIndex:1001,width:'80%',height:'60vh'}}>
             <div style={{marginTop:'20px',width:'80%',height:'60vh'}} class="bg-white max-w-md mx-auto flex items-center justify-center shadow border rounded  w-full p-10 ">
        
        <div
        className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
        role="status"></div></div></div>
        :
sent ===  true?
<div style={{width:'80%',height:'60vh'}}class="bg-white shadow border rounded  w-full  ">
            <div  id='successCourseAddDesktop' >


<div className="relative p-4 w-full  "style={{width:"100%"}}>
<div className="relative p-4 text-center bg-white rounded-lg   sm:p-5 mt-12" style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}} >

<button  onClick={successRemove}  type="button" className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
   <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
   <span className="sr-only">Close modal</span>
</button>


<div className="w-12 h-12 rounded-full bg-green-400 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
   <svg aria-hidden="true" className="w-8 h-8 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
   <span className="sr-only">Success</span>
</div>
<p className="mb-4 text-lg font-semibold text-gray-900  capitalize">Email has been sent</p>
            <p className='borel'>Please check your email inbox for a password recovery link</p>
</div>
</div>



</div> 
</div>
:
forgotPassword === true ?
<div style={{width:'80%',height:'60vh'}}class="bg-white shadow border rounded  w-full p-10 relative">
<button  onClick={successRemove}  type="button" className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
   <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
   <span className="sr-only">Close modal</span>
</button>
<div className={`${resetEmailError.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{resetEmailError}
</div>
</div>
</div>
<p style={{fontSize:'20px'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800">Reset Password</p>



<div className="relative mx-auto mt-12" style={{width:'80%'}}>
<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Email</p>
<input onChange={(e)=>resetEmailSet(e)}style={{fontSize:'12px',height:'60px'}}   required  autoComplete="off" placeholder="kebede@gmail.com" type="email" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-signUp" />

<div className={`${forgotPasswordError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Email Can't be empty
</div>
</div>
</div>
</div>

<div className="flex justify-center mt-12 mx-auto" style={{width:'80%'}}>
<button
type='button'
onClick={resetEmail}
style={{fontSize:'12px',margin:'auto',background:'#31ffaa',height:'55px'}}
className='flex justify-center items-center par border w-full text-gray-800  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
>
Reset Password
</button>
</div>
<div class="w-full flex items-center justify-between py-5 mt-4">
</div>



</div>

             :
             <div style={{width:'80%',height:'60vh'}}class="relative bg-white shadow border rounded  w-full p-5 ">
             <div className={`${signInError.length !== 0 ? "block":"hidden"}`}>
             <div style={{paddingTop:'3vw',paddingBottom:'3vw',paddingLeft:'4vw',width:'100%',marginBottom:'3vw'}} className="flex items-center text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
             <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
             <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
             </svg>
             <span className="sr-only">Info</span>
             <div className='Mooli' style={{fontSize:'3vw'}}>
             {signInError}
             
             </div>
             </div>
             </div> 
             <svg onClick={setsigninpopClose} xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-x-lg absolute right-6 top-4 cursor-pointer hover:text-red-700" viewBox="0 0 16 16">
   <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z"/>
 </svg>
             <p style={{fontSize:'5vw',marginBottom:'8vw'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800">Log In</p>
             <form class="max-w-md mx-auto p-10" style={{marginTop:'20px'}}>
 <div className={`${emailError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'12px'}}>
Email Can't be empty
</div>
</div>
</div>
<div className={`${passwordError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'12px'}}>
Password Can't be empty
</div>
</div>
</div>
<div className={`${signInError ? "block":"hidden"}`}>
<div className="flex items-center p-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'12px'}}>
{signInError}
</div>
</div>
</div>
  <div class="relative z-0 w-full my-5 group">
      <input onChange={e=>emailSet(e)} type="email" name="floating_email" id="floating_email" style={{borderBottom:'1px solid black',borderRadius:'0',marginBottom:'20px'}}class="input-signUp block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
      <label for="floating_email" class=" peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-green-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address</label>
      <div className={`${emailError === "error" ? "block":"hidden"}`}>

</div>
  </div>
  


    <div class="relative z-0 w-full mb-5 group">
        <input type="password" onChange={e=>passwordSet(e)} name="floating_phone" id="floating_phone" style={{borderBottom:'1px solid black',borderRadius:'0',marginBottom:'20px'}}class="input-signUp block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
        <label for="floating_phone" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7  scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-green-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Password </label>
    </div>
  
   
              <button onClick={signIn} type="submit" className="button-secondary button bg-transparent">Sign In</button>

              <p onClick={openForgotPassword} style={{fontSize:'12px'}} className='cursor-pointer mt-5 flex justify-center '>
                    Forgot Password?
                     
                      
                    </p>
</form>
           
             
                <p style={{fontSize:'12px'}} class="w-full  text-center par focus:outline-none text-sm  font-medium leading-none text-gray-500">Don't have an account? <Link to='/register'><a   class="hover:text-gray-500 focus:text-gray-500 focus:outline-none focus:underline hover:underline text-sm font-medium leading-none  text-gray-800 cursor-pointer underline" style={{fontSize:'12px'}}> Sign up here</a></Link></p>
              
               
             </div>}
           </div>
 
          }
             <div className="flex justify-between px-5 items-center">
 
 
 
             </div>
      
             <div >
 
             <div  >
             {
                 cloth && cloth.map(i=>{
                     const{clothSize,clothDelivery,clothTitle,clothColor,clothDescription,clothIngredients,clothPrice,clothFabric} = i
                     return(
                         <div class=" p-5">
                         <div class=" mx-auto ">
                         <h1 className='barlow pl-10 pt-5 mb-2 flex items-center capitalize' style={{fontSize:"12px"}}> 
      <Link className='underline' to={`/collection`}>Clothes</Link>
 <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-chevron-right mx-1" viewBox="0 0 16 16">
   <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
 </svg> 
 
 
  {clothTitle}</h1>
           
                             <div class="flex flex-col w-full">
                                 <div class="" style={{width:'100%'}}>
                                 {cloth && cloth.map((cloth) => (
               <div key={cloth.id}>
                 <ClothGallery
                   cloth={cloth}
                   currentIndex={currentIndexes[cloth.id] || 0}
                   handlePrevious={(clothPhotos) => handlePrevious(clothPhotos, cloth.id)}
                   handleNext={(clothPhotos) => handleNext(clothPhotos, cloth.id)}
                 />
               </div>
             ))}
                                
                                 </div>
                                 <div class="mx-auto" style={{width:'90%'}}>
                                   <div className='flex justify-between w-full items-center' style={{paddingTop:'6vw'}}>
                                   <h2 style={{fontSize:'4.8vw'}} class="text-2xl font-bold text-gray-900  mb-2 barlow">{clothTitle}</h2>
                                     
                                   <p style={{fontSize:'3.5vw'}} class="text-gray-800 header">{formatNumber(clothPrice)} ETB </p>
 
                                   </div>
                                     <div className='mb-8'>
                                         <p class="text-gray-600  text-sm mt-2 par break-all" style={{fontSize:'2.8vw',lineHeight:'3.2vw'}}>
                                     {clothDescription} </p>
                                     </div>
                                     <div class="mb-4">
                                         <span class="font-bold text-gray-700" style={{fontSize:'3.5vw'}}>Cloth Size:</span>
                                        <div className='text-gray-600 par' style={{fontSize:'2.8vw'}}>
 
                                       {clothSize}
                                     </div>
                                     </div>
                                     <div class="flex mb-4">
                                      
                                         <div>
                                             <span class="font-bold text-gray-700 "style={{fontSize:'3.5vw'}}>Cloth Fabric:</span>
                                             {clothFabric && clothFabric.map(i=>{
                                              return(
                                                <p class="text-gray-600 par" style={{fontSize:'2.8vw'}}>{i} </p>

                                              )
                                             })}
                                         </div>
                                     </div>
                                  
                               
 
                                     <div class="mb-4" style={{marginBottom:'6vw'}}>
                                         <span class="font-bold text-gray-700 mb-2" style={{fontSize:'3.5vw'}}>Delivery Status</span>
                                         <p class="text-gray-600 par" style={{fontSize:'2.8vw'}}>                  {clothDelivery === 'Shop Pickup' ? `We do not offer delivery services, so you must pick up the cloth from our shop`:'Delivery Available'}
                                         </p>

 
                                     </div>
                             
                                     {
                                       uid.length === 0 ?
                                       <button  style={{fontSize:'3vw',width:'100%',height:'13vw',backgroundColor:'black'}}onClick={signInPopSet} className="mt-5   w-full flex items-center justify-center rounded-md bg-slate-900 barlow text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
                                       <svg
                                         xmlns="http://www.w3.org/2000/svg"
                                         className="mr-2 "
                                         style={{width:'4vw',height:'4vw'}}
                                         fill="none"
                                         viewBox="0 0 24 24"
                                         stroke="currentColor"
                                         strokeWidth="2"
                                       >
                                         <path
                                           strokeLinecap="round"
                                           strokeLinejoin="round"
                                           d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                                         />
                                       </svg>
                                       Add to cart
                                     </button>
                                     : <button style={{fontSize:'3vw',width:'100%',height:'13vw',backgroundColor:'black'}} onClick={()=>addToCart(clothColor)} className="mt-5   w-full flex items-center justify-center rounded-md bg-slate-900 barlow text-center text-sm font-medium text-white hover:bg-gray-700 focus:outline-none focus:ring-4 focus:ring-blue-300">
                                     <svg
                                       xmlns="http://www.w3.org/2000/svg"
                                       className="mr-2 "
                                       style={{width:'4vw',height:'4vw'}}
 
                                       fill="none"
                                       viewBox="0 0 24 24"
                                       stroke="currentColor"
                                       strokeWidth="2"
                                     >
                                       <path
                                         strokeLinecap="round"
                                         strokeLinejoin="round"
                                         d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                                       />
                                     </svg>
                                     Add to cart
                                   </button>
 
                                     }
                 
                   {
                     uid.length === 0?
       <button style={{fontSize:'3vw',width:'100%',height:'13vw',backgroundColor:'#0048ff'}} type="submit"onClick={signInPopSet} class="flex barlow w-full items-center justify-center rounded-lg bg-blue-700  text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-300  mt-4">Proceed to Checkout</button>
 :
 <button style={{fontSize:'3vw',width:'100%',height:'13vw',backgroundColor:'#0048ff'}}type="submit"onClick={()=>shopItem(clothColor)} class="flex barlow w-full items-center justify-center rounded-lg bg-blue-700  text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-4 focus:ring-blue-300  mt-4">Proceed to Checkout</button>
 
                   }
 
                                 </div>
                             </div>
                         </div>
                     </div>
 
                     )
                 })
 
 }
             </div>
          
             </div>
           
             </div>
             :
             <div>
                <div className='flex items-center justify-center flex-col  '>
<img src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/red%20velvet%20cloth-pana.svg?alt=media&token=2749019d-15fe-499d-af3e-8342c9ef434a" alt="No cloth" style={{width:"42%"}}/>
<h1 className='fjalla text-center text-gray-900 mt-8 barlow' style={{fontSize:"5vw"}}>  We couldn't find any Clothes for you 

       


</h1>
<Link to='/collection'>
        <button type="button" class="text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm px-7 py-4 me-2 mb-2">Shop Now</button> 

         </Link>
</div>
             </div>
        }
            

        </div>
        </div>
  )
}
