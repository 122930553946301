import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom/cjs/react-router-dom'

import './style.css'
import Home from './views/home'
import Contact from './views/contact'
import NotFound from './views/not-found'
import Login from './components/login'
import Register from './components/register'
import Collection from './components/collection'
import About from './components/About'
import Cloth from './views/cloth'
import Profile from './views/profile'
import Checkout from './views/checkout'
import Orders from './views/orders'
import Women from './components/women'
import Kids from './components/kids'
import OrderDetails from './views/orderDetails'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={Home} exact path="/" />
        <Route component={Contact} exact path="/contact" />
        <Route component={Login} exact path="/login" />
        <Route component={Register} exact path="/register" />
        <Route component={Women} exact path="/collection/women" />
        <Route component={Kids} exact path="/collection/kids" />
        <Route component={OrderDetails} exact path="/orders/:orderId" />

        <Route component={Collection} exact path="/collection" />
        <Route component={About} exact path="/about" />
        <Route component={Profile} exact path="/profile" />
        <Route component={Checkout} exact path="/collection/:uniqueId/checkout" />
        

        <Route component={Cloth} exact path="/cloth/:clothId" />
        <Route component={Orders} exact path="/orders" />


        {/* Admin */}




        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
