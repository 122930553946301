import React, { Fragment, useState } from 'react'

import PropTypes from 'prop-types'

import './contact8.css'

const Contact8 = (props) => {
  
  return (
          <div className="home-section2 section-container" style={{backgroundColor:'#fffef3ff'}}>

      <div className="contact8-max-width thq-section-max-width">
      <p className="thq-body-large contact8-text11">
            <span className="thq-body-small contact-form5-text12">
                <span 
                   style={{fontSize:'25px',marginTop:'50px',textAlign:'center',display:'flex',justifyContent:'center'}}       className="nds-text _1MF91zHG css-7vq5hu e1yhcai00 appearance-display2 text-white weight-medium uppercase"
                   >Get in touch with us today!</span>
            </span>
            </p>
        <div className="flex items-center justify-evenly w-full mt-12">
            
          <div className="contact8-content2 mr-10">
          
          
            <div className="contact8-content3 thq-flex-row">
          <div className="contact8-container2">
            <iframe
              src={props.location1GoogleMap}
              title="Map"
              className="contact8-iframe1"
            ></iframe>
           
           
          
          </div>
          <div className="contact8-container4">
         
           
         
           
          </div>
        </div>
          </div>
          <div className="home-image6">
          <span className="home-text123 beforeHeading">Our Address</span>
            <h1 className="home-text124">
              <span className="home-text127">Lebu</span>
              <br></br>

              <span className="home-text125">Addis Ababa, Ethiopia</span>

            </h1>
            <li class="flex">
                            <div class="flex h-10 w-10 items-center justify-center rounded bg-green-900 text-gray-50">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="h-6 w-6">
                                    <path
                                        d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2">
                                    </path>
                                    <path d="M15 7a2 2 0 0 1 2 2"></path>
                                    <path d="M15 3a6 6 0 0 1 6 6"></path>
                                </svg>
                            </div>
                            <div class="ml-4 mb-4">
                                <h3 class="mb-2 text-lg font-medium leading-6 text-gray-900 header">Contact
                                </h3>
                                <p class="text-gray-600 ">0991440729</p>

                                <p class="text-gray-600 ">rimmondesign@yahoo.com</p>
                            </div>
                        </li>
          </div>
          
        </div>
      
      </div>
    </div>
  )
}

Contact8.defaultProps = {
  location2Details: undefined,
  location2: undefined,
  heading1: undefined,
  location1Details: undefined,
  location1: undefined,
  content1: undefined,
  location2GoogleMap:
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d492.658407857482!2d38.7292115!3d8.947502!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b8167708c09cf%3A0x927e59a2e7c06c1e!2sWPWH%2BXQ8%2C%20Addis%20Ababa!5e0!3m2!1sen!2set!4v1731756677462!5m2!1sen!2set" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade',
  location1GoogleMap:
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d492.658407857482!2d38.7292115!3d8.947502!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x164b8167708c09cf%3A0x927e59a2e7c06c1e!2sWPWH%2BXQ8%2C%20Addis%20Ababa!5e0!3m2!1sen!2set!4v1731756677462!5m2!1sen!2set" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade',
}

Contact8.propTypes = {
  location2Details: PropTypes.element,
  location2: PropTypes.element,
  heading1: PropTypes.element,
  location1Details: PropTypes.element,
  location1: PropTypes.element,
  content1: PropTypes.element,
  location2GoogleMap: PropTypes.string,
  location1GoogleMap: PropTypes.string,
}

export default Contact8
